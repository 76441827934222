
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { QUESTION } from '@/types/question';
import type { IQuestionValue } from '@/interface/survey/question';
import Radio from '@/components/project/make/join/Radio.vue';
import RadioSet from '@/components/project/make/join/RadioSet.vue';
import RadioSets from '@/components/project/make/join/RadioSets.vue';
import Check from '@/components/project/make/join/Check.vue';
import CheckSets from '@/components/project/make/join/CheckSets.vue';
import MultiText from '@/components/project/make/join/MultiText.vue';
import TextArea from '@/components/project/make/join/TextArea.vue';
import InfoDesc from '@/components/project/make/join/InfoDesc.vue';
import GradeClick from '@/components/project/make/join/GradeClick.vue';
import InfoTitle from '@/components/project/make/join/InfoTitle.vue';
import QuestionEdit from '@/components/project/make/question/QuestionEdit.vue';
import '@/assets/css/getdata/common.css';
import '@/assets/css/getdata/getdata.css';
import '@/assets/css/getdata/join.css';
import '@/assets/css/getdata/pure-min.css';
import '@/assets/css/skin/PMI2/index.css';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import Question from '@/components/project/make/question/Question.vue';

const { QUESTION_TYPES } = QUESTION;

@Component({
  components: {
    Question,
    Radio,
    RadioSet,
    MultiText,
    InfoDesc,
    GradeClick,
    InfoTitle,
    TextArea,
    RadioSets,
    Check,
    CheckSets,
    QuestionEdit,
    TextBoxModal,
  },
})
export default class QuestionLists extends Vue {
  @Prop({ required: true }) readonly item: IQuestionValue;
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop() readonly dataIndex: number;
  @Prop({ default: '' }) readonly currentDataName: string;
  @Prop() readonly questionName: string[];
  @Prop() readonly nextOrPrev: string;
  @Prop() readonly go: boolean;

  editQuestion: IQuestionValue = QUESTION.tempQuestionData();
  QUESTION: Object = QUESTION;
  btnHover = false;
  qnum = '';

  @Emit('modalController')
  editOptions(type: string, qnum: string) {
    return { type, qnum };
  }

  @Watch('item')
  watchItem() {
    this.editQuestion = this.item;
    this.item.EDIT = false;
  }

  @Watch('go')
  goWatch() {
    if (this.go && this.qnum) this.questionSwitch(this.qnum);
    else this.qnum = '';
  }

  questionEdit(type: boolean) {
    this.item.EDIT = type;
  }

  get questionTypeName(): string {
    const type = QUESTION.QUESTION_TYPES_LIST.find((question) => question.value === this.item.TYPE);
    const { text } = type || { text: '' };
    return text;
  }

  get isCurrent(): boolean {
    return this.currentDataName === this.item.NAME;
  }

  get isFixed(): boolean {
    return QUESTION.FIXED_QUESTIONS.indexOf(this.item.NAME) > -1;
  }

  btnHoverCheck({value, type}) {
    this.btnHover = value;
    this.$emit('next-or-pre-event', type)
  }
  questionOpen(QNAME: string) {
    if (this.item.EDIT || this.btnHover) return;
    this.qnum = QNAME;
    const exceptedQuestionType = ['SQ1', 'SQ2', 'SQ3'];
    const isEditMode = this.list.some(v => v.EDIT && !exceptedQuestionType.includes(v.NAME));
    if (isEditMode && this.$question.makeUpdateStatus) {
      this.$nextTick(() => this.$bvModal.show(`question-list-alert-modal`));
      return;
    }
    this.questionSwitch(QNAME);
  }

  questionSwitch(QNAME: string) {
    this.$emit('questionSwitch', QNAME);
    this.qnum = '';
    this.btnHover = false;
  }
}
