
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IQuestionValue } from '@/interface/survey/question';

@Component
export default class QuestionModalSave extends Vue {
  @Prop({ default: '' }) modalId: string;
  @Prop({ default: '' }) question: IQuestionValue;

  close() {
    this.$bvModal.hide(this.modalId);
  }

  async confirm() {
    this.$emit('dataSave');
    this.close();
  }
}
