
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import QuestionLists from '@/components/project/make/question/QuestionLists.vue';
import { IQuestionValue } from '@/interface/survey/question';
import AlertModal from '@/components/modal/make/option/AlertModal.vue';

@Component({
  components: {
    AlertModal,
    QuestionLists,
  },
})
export default class Question extends Vue {
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop({ default: '' }) readonly currentDataName: string;
  @Prop() readonly questionName: string[];
  @Prop() readonly loading: boolean;
  @Prop() readonly nextOrPrev: string;
  go = false;
  nextOrPrevEvent(type: string) {
    this.$emit('next-or-pre-event', type)
  }

  cancel() {
    this.go = false;
  }
  alertConfirm() {
    this.go = true
  }

  cardClass(name: string) {
    const isEqualName = this.currentDataName === name;
    const defaultClass = '';
    const addPrimaryClass = defaultClass + ' selected-question';
    return isEqualName ? addPrimaryClass : defaultClass;
  }

  questionSwitch(QNAME) {
    this.$emit('questionSwitch', { QNUM: QNAME, naviScroll: true });
    this.go = false;
  }

  @Emit('modalController')
  modalController(emit: { type: string; qnum: string }) {
    return emit;
  }
}
