
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IQuestionOptions } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';

import {
  CheckIcon,
  CheckSetsIcon,
  GetExampleIcon,
  TextAreaIcon,
  GradeIcon,
  RadioIcon,
  RadioSetIcon,
  RadioSetsIcon,
  TitleIcon,
  DescriptionIcon,
  MultiTextIcon,
} from '@/components/project/make/options/common/icons';
import { STYLE } from '@/interface/style';
import EventBus from '@/utils/EventBus';

@Component({
  components: {
    RadioIcon,
    RadioSetIcon,
    TitleIcon,
    DescriptionIcon,
    RadioSetsIcon,
    MultiTextIcon,
    CheckIcon,
    CheckSetsIcon,
    GetExampleIcon,
    TextAreaIcon,
    GradeIcon,
  },
})
export default class QuestionCategory extends Vue {
  @Prop() notSelectedQuestion: boolean;

  categoryList: IQuestionOptions[] = [...QUESTION.QUESTION_TYPES_LIST];
  categoryClassName: string = STYLE.QUESTION_CATEGORY;
  selectCategoryIndex: number = -1;
  categoryHover: number = -1;

  selectCategory(cIndex) {
    if (this.selectCategoryIndex !== cIndex) {
      this.selectCategoryIndex = cIndex;
    } else {
      this.selectCategoryIndex = -1;
    }
    this.$emit('selectCategory', this.selectCategoryIndex);
  }

  iconComponents(cIndex: number) {
    switch (cIndex) {
      case 0:
        return RadioIcon;
      case 1:
        return CheckIcon;
      case 2:
        return GetExampleIcon;
      case 3:
        return RadioSetIcon;
      case 4:
        return GradeIcon;
      case 5:
        return RadioSetsIcon;
      case 6:
        return MultiTextIcon;
      case 7:
        return TextAreaIcon;
      case 8:
        return CheckSetsIcon;
      case 9:
        return TitleIcon;
      case 10:
        return DescriptionIcon;
    }
  }
  /**
   * 문항 생성 미리보기
   * @param index
   */
  popoverConfigData(index: number) {
    const category: IQuestionOptions = this.categoryList[index];
    const { value, desc } = category;
    if (value === 'CHILD') return;
    const popoverFlag = QUESTION.QUESTION_TYPES_LIST.findIndex((item) => item.value === value) > -1;
    return !popoverFlag
      ? { disabled: true }
      : {
          html: true,
          delay: {
            show: 400,
          },
          placement: 'bottom',
          customClass: 'question-description-img',
          content: `<div >
                        <b class="nanumgothicotf-bold-chicago-15px pb-2">${value} - ${desc}</b>
                        <img style='width:710px;' src='https://metasurvey.io/Project/Make/Images/Sample/${value}.png' alt="문항 설명" />
                    </div>`,
        };
  }
}
